<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <h2 class="text-center my-4">Novi radnik</h2>
      </b-col>
      <b-col class="flex-center">
        <b-form v-on:submit.prevent="createEmployee" class="w-75">
          <b-form-group :label-cols-md="4" label="Ime:" label-for="name">
            <b-form-input id="name" type="text" size="sm" name="first_name" required>
            </b-form-input>
          </b-form-group>
          <b-form-group :label-cols-md="4" label="Prezime:" label-for="last_name">
            <b-form-input id="last_name" type="text" size="sm" name="last_name" required>
            </b-form-input>
          </b-form-group>
          <b-form-group :label-cols-md="4" label="Adresa prebivališta:" label-for="residence_address">
            <b-form-input
              id="residence_address"
              type="text"
              size="sm"
              name="residence_address"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            :label-cols-md="4"
            label="Općina prebivališta:"
            label-for="residence_township_id"
          >
            <select
              id="residence_township_id"
              class="form-control form-control-sm"
              name="residence_township_id"
              required
            >
              <option selected disabled>Izaberite općinu prebivališta</option>
              <option v-for="township in townships" :value="township.id">
                {{ township.name }}
              </option>
            </select>
          </b-form-group>
          <b-form-group :label-cols-md="4" label="Adresa boravišta:" label-for="acurrent_ddress">
            <b-form-input
              id="current_address"
              type="text"
              size="sm"
              name="current_address"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            :label-cols-md="4"
            label="Općina boravišta:"
            label-for="current_township_id"
          >
            <select
              id="current_township_id"
              class="form-control form-control-sm"
              name="current_township_id"
              required
            >
              <option selected disabled>Izaberite općinu boravišta</option>
              <option v-for="township in townships" :value="township.id">
                {{ township.name }}
              </option>
            </select>
          </b-form-group>
          <b-form-group :label-cols-md="4" label="Telefon:" label-for="phone">
            <b-form-input
              id="phone"
              type="text"
              size="sm"
              name="phone"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group :label-cols-md="4" label="JMBG:" label-for="jmbg">
            <b-form-input
              id="jmbg"
              type="text"
              size="sm"
              name="jmbg"
              maxlength="13"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-btn type="submit" variant="primary" class="pull-right"
            >Potvrdi</b-btn
          >
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  computed: {
    selectedClient: function() {
      return this.$store.getters.selectedClient;
    }
  },
  data() {
    return {
      townships: []
    };
  },
  methods: {
    createEmployee: function(e) {
      const formData = new FormData(e.target);
      formData.append("client_id", this.selectedClient.id);
      axiOsta.post(api.employees, formData).then(response => {
        if (response.status === 201) {
          this.$router.push("/employees/" + response.data.id);
        }
      });
    }
  },
  created() {
    axiOsta.get(api.townships).then(response => {
      if (response.status === 200) {
        this.townships = response.data;
      }
    });
  }
};
</script>
